<template>
  <v-container>
    <h1 class="mb-5">Организации</h1>

    <div class="d-md-flex d-sm-block">
      <div class="mr-md-4 mr-xs-0">
        <v-card class="search-container mb-5">
          <organizations-filters
            :pending="organizations.pending"
            :reset-trigger="resetTrigger"
            @handleSearch="handleSearch"
          />
        </v-card>
      </div>

      <div class="flex-grow-1">
        <div style="min-height: 4px">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            :active="organizations.pending"
          ></v-progress-linear>
        </div>

        <div v-if="!organizations.pending && organizations.total" class="mb-3">
          <strong
            >Найдено {{ organizations.total.toLocaleString("ru") }}
            {{ searchTotalString }}</strong
          >
        </div>

        <v-row class="mb-3">
          <v-col
            v-for="organization in organizations.list"
            :key="organization.id"
            cols="12"
            sm="6"
            md="12"
            lg="6"
          >
            <div class="search-card">
              <v-sheet class="pa-4 org-card body-1">
                <organization-card
                  :key="organization.id"
                  class="search-card__org"
                  :organization="organization"
                  list-version
                />
              </v-sheet>
            </div>
          </v-col>
        </v-row>

        <div v-if="showClearFiltersMessage">
          Организаций по выбранным параметрам нет.
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="handleClearFilters"
            >Сбросить фильтры</a
          >
        </div>

        <div v-if="organizations.error" class="error--text">
          {{ organizations.error }}
        </div>

        <v-card-actions v-show="organizations.pagesCount > 1" class="pb-4 pt-0">
          <v-pagination
            v-model="page"
            class="justify-start"
            :total-visible="7"
            :length="organizations.pagesCount"
            :disabled="organizations.pending"
          ></v-pagination>
        </v-card-actions>
      </div>
    </div>
  </v-container>
</template>
<script>
import { initialListingModel, numCases } from "@/utils";
import { talentClient } from "@/api";
import OrganizationsFilters from "@/components/organizations/OrganizationsFilters.vue";
import OrganizationCard from "@/components/organizations/OrganizationCard.vue";
import { isEqual } from "lodash";
import { initialOrganizationsFilters } from "@/components/organizations/helpers";
export default {
  name: "Organizations",
  components: {
    OrganizationsFilters,
    OrganizationCard,
  },
  metaInfo() {
    return {
      title: "Список организаций",
    };
  },
  data() {
    return {
      organizations: initialListingModel(20),
      resetTrigger: false,
      isFiltersEmpty: true,
    };
  },
  computed: {
    searchTotalString() {
      return numCases(
        ["организация", "организации", "организаций"],
        this.organizations.total
      );
    },
    showClearFiltersMessage() {
      return (
        !this.organizations.pending &&
        !this.organizations.list.length &&
        !this.organizations.error &&
        !this.isFiltersEmpty
      );
    },
    page: {
      get() {
        return this.organizations.page;
      },
      set(val) {
        this.setPage(val);
      },
    },
  },
  watch: {
    "$route.query": {
      handler(query) {
        const filters = this.mapQueryToFilters(query);
        this.isFiltersEmpty = !Object.keys(filters).some((key) => {
          return filters[key];
        });
      },
      immediate: true,
    },
  },
  created() {
    const filters = this.mapQueryToFilters(this.$route.query);
    this.getOrganizations({ ...filters, page: this.$route.query.page });
  },
  methods: {
    async getOrganizations(params = {}) {
      if (this.organizations.pending) return;
      this.organizations.error = "";
      this.organizations.pending = true;
      try {
        const { page, ...rest } = params;
        const newPage = Number(page) || 1;
        const { data } = await talentClient({
          method: "GET",
          url: "organizations/",
          params: {
            limit: this.organizations.limit,
            offset: (newPage - 1) * this.organizations.limit,
            ...(rest && {
              ...rest,
            }),
          },
        });
        this.organizations.total = data.count;
        this.organizations.list = data.results;
        this.organizations.pagesCount = Math.ceil(
          data.count / this.organizations.limit
        );
        this.organizations.page = newPage;
      } catch (error) {
        this.organizations.error = error.message;
      }
      this.organizations.pending = false;
    },

    handleSearch(filters) {
      const query = { ...filters, page: "1" };
      this.getOrganizations(query);

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({
          query,
        });
      }
    },
    setPage(page) {
      if (this.organizations.page === page) return;
      const query = { ...this.$route.query, page: String(page) };
      this.getOrganizations(query);
      this.$router.push({ query });
    },
    handleClearFilters() {
      this.resetTrigger = true;
      this.$nextTick(() => {
        this.resetTrigger = false;
      });
    },
    mapQueryToFilters(query) {
      const filters = initialOrganizationsFilters();
      Object.keys(filters).forEach((key) => {
        filters[key] = query[key];
      });

      return filters;
    },
  },
};
</script>
<style lang="scss">
.search-container {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 961px) {
    width: 100%;
    position: static;
  }
}

.search-card {
  display: flex;
  min-height: 100%;
  background-color: #fff;
  flex-direction: column;
  position: relative;
  &__org {
    flex-grow: 1;
  }
}
</style>
