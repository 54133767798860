<template>
  <v-card class="search mb-5">
    <v-card-title
      class="text-h6 rm-dialog-title pb-0 d-flex justify-space-between"
      ><span>Фильтры</span
      ><v-btn
        fab
        x-small
        color="#9e9e9e"
        class="ml-2"
        elevation="0"
        :title="isExtraFilters ? 'Скрыть фильтры' : 'Показать фильтры'"
        @click="isExtraFilters = !isExtraFilters"
      >
        <v-icon size="24" color="#fff">{{
          isExtraFilters ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn></v-card-title
    >
    <v-card-text>
      <v-form @submit.prevent="handleSearch">
        <v-text-field
          v-model.trim="filters.search"
          label="Название или описание"
          clearable
        />
        <v-expand-transition>
          <div v-show="isExtraFilters">
            <address-input
              v-model.trim="filters.city"
              :initial-value="filters.city"
              filter="city"
              label="Город"
            />
            <v-text-field
              v-model.trim="filters.legal_entity"
              label="ID Юр. Лица"
              clearable
            />
            <v-text-field
              v-model.trim="filters.legal_entity_inn"
              label="ИНН Юр. Лица"
              clearable
            />
            <v-text-field
              v-model.trim="filters.member"
              label="ID участника"
              clearable
            />
            <v-text-field
              v-model.trim="filters.ids"
              label="ID Организаций"
              clearable
              hint="Введите ID организаций через запятую без пробела"
            />
            <v-text-field
              v-model.trim="filters.parent_id"
              label="Родительская организация"
              clearable
              hint="Введите ID"
            /></div
        ></v-expand-transition>
        <v-btn
          color="primary"
          elevation="0"
          :loading="pending"
          :disabled="pending"
          type="submit"
          width="100%"
          class="mb-2"
        >
          Искать
        </v-btn>
        <v-btn
          color="primary"
          outlined
          elevation="0"
          width="100%"
          :disabled="isFiltersEmpty"
          @click="handleClearFilters"
        >
          Сбросить фильтры
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import AddressInput from "@/components/contact/AddressInput";
import { initialOrganizationsFilters } from "./helpers";
export default {
  name: "OrganizationsFilters",
  components: {
    AddressInput,
  },
  props: {
    pending: Boolean,
    resetTrigger: Boolean,
  },
  data() {
    return {
      isExtraFilters: true,
      filters: initialOrganizationsFilters(),
    };
  },
  computed: {
    isFiltersEmpty() {
      const { filters } = this;
      return !Object.keys(filters).some((key) => {
        return filters[key];
      });
    },
  },
  watch: {
    resetTrigger(val) {
      if (val) {
        this.handleClearFilters();
      }
    },
  },
  created() {
    const { query } = this.$route;
    this.filters = Object.keys(this.filters).reduce((acc, key) => {
      if (query[key]) {
        acc[key] = query[key];
      }
      return acc;
    }, this.filters);
  },
  methods: {
    handleSearch() {
      const params = {};
      for (const key in this.filters) {
        if (this.filters[key]) {
          params[key] = this.filters[key];
        }
      }
      this.$emit("handleSearch", params);
    },
    handleClearFilters() {
      this.filters = initialOrganizationsFilters();
      this.handleSearch();
    },
  },
};
</script>
<style lang="scss">
.search {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 961px) {
    width: 100%;
    position: static;
  }
}
</style>
