<template>
  <organizations-section
    :key="updateKey"
    :organizations="organizations"
    @setPage="getOrganizations"
  />
</template>
<script>
import { OrganizationsSection } from "@/components/contact/sections";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
export default {
  name: "ContactOrganizationsPage",
  components: {
    OrganizationsSection,
  },
  data() {
    return {
      organizations: initialListingModel(10),
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Организации`,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getOrganizations(this.organizations.page);
    this.updateKey += 1;
  },
  methods: {
    async getOrganizations(page = 1) {
      if (this.organizations.pending) return;
      const { talentId } = this;
      this.organizations.pending = true;
      this.organizations.error = "";

      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${talentId}/organizations/`,
          params: {
            limit: this.organizations.limit,
            offset: (page - 1) * this.organizations.limit,
          },
        });
        this.organizations = {
          ...this.organizations,
          list: data.results,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.organizations.limit),
          page,
        };
      } catch (error) {
        this.organizations.error = error.message;
      }
      this.organizations.pending = false;
    },
  },
};
</script>
